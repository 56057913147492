<template>
  <div
    v-if="data.fields"
  >
    <h1>{{data.fields.headline}}</h1>
    <p>{{data.fields.tagline}}</p>

    <div class="selector-wrapper" v-if="data.fields.mongoDBCharts && data.fields.mongoDBCharts.filteredCharts">
      <el-select
        v-model="selection"
        placeholder="——"
        key="selector"
        :popper-append-to-body="false"
      >
        <el-option
          v-for="(key, value) in data.fields.mongoDBCharts.filteredCharts"
          :key="value"
          :label="value"
          :value="value"
        ></el-option>
      </el-select>
    </div>


    <div class="mongodb-charts-wrapper" v-if="chartsList">
      <div
        :id="`chart-${chart.id}`"
        v-for="chart in chartsList"
        class="chart-block"
        :class="{'large': chart.large}"
      ></div>
    </div>
  </div>
</template>

<script>
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom'

export default {
  name: 'MongoDBChartFiltered',

  data () {
    return {
      selection: ''
    }
  },

  transition: 'page',

  props: [ 'data' ],

  computed: {
    chartsList () {
      if (this.data.fields.mongoDBCharts && this.data.fields.mongoDBCharts.filteredCharts) {
        var list = this.data.fields.mongoDBCharts.filteredCharts[this.selection]
        return list
      }
      return []
    }
  },

  methods: {
  },

  mounted () {
    if (this.$route.query.selection && this.data.fields.mongoDBCharts && this.data.fields.mongoDBCharts.filteredCharts) {
      if (JSON.stringify(this.data.fields.mongoDBCharts.filteredCharts).indexOf(this.$route.query.selection) > -1) {
        this.selection = this.$route.query.selection
      }
    }
  },

  watch: {
    'selection': function (newValue) {
      setTimeout(() => {
        const sdk = new ChartsEmbedSDK({
          baseUrl: this.data.fields.mongoDBCharts.url
        })
        this.chartsList.forEach((key, index) => {
          const chart = sdk.createChart({ chartId: key.id, showAttribution: false })
          chart.render(document.getElementById(`chart-${key.id}`))
        })      
      }, 1000)      
    }
  }
}
</script>

<style scoped lang="scss">
.selector-wrapper {
  margin-bottom: 2em;
}
.mongodb-charts-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 100%;
  border-top: 1px solid $gray-light;
  padding-top: 1em;
  justify-content: space-between;
  align-items: flex-start;
}
.chart-block {
  width: 100%;
  height: 400px;
  border: 1px solid $gray-light;
  margin-bottom: 1em;
  @include breakpoint($tablet) {
    width: calc((100vw - 4em) / 3);
    height: 500px;
  }
  &.large {
    @include breakpoint($tablet) {
      width: calc((100vw - 4em) / 3 * 2);
    }
  }
  @include breakpoint($fullhd) {
    width: calc((100vw - 5em) / 4);
    height: 500px;
  }
  &.large {
    @include breakpoint($fullhd) {
      width: calc((100vw - 5em) / 4 * 2);
    }
  }
}
</style>
<style lang="scss">
.selector-wrapper {
  .el-input.is-focus .el-input__inner,
  .el-input__inner:focus {
    border-color: $color-emphasis;
  }
  .el-select-dropdown__item.selected {
    color: $text-main;
  }
}
</style>